import { OPTION_OTHER } from '@/views/form/content/utils';
import { IBaseFormDTO } from './formContent';
import { WithNull } from './helper';

/**
 * 用药出现原因
 * 注：story#16760 用药出现原因只显示常规提供且不可编辑
 *
 * @enum
 */
export enum SalesIncreaseReasonType {
  WEEKEND = '周末用药',
  HOLIDAY = '假期用药',
  REENTRY_AFTER_DELETED = '信息删除后补录',
  CODE_ADDED = '编码新增',
  REGULAR_OFFER = '常规提供',
  OTHER = '其他',
  NONE = '',
}

export const SalesIncreaseReasonTypes = [
  SalesIncreaseReasonType.WEEKEND,
  SalesIncreaseReasonType.HOLIDAY,
  SalesIncreaseReasonType.REENTRY_AFTER_DELETED,
  SalesIncreaseReasonType.CODE_ADDED,
  SalesIncreaseReasonType.REGULAR_OFFER,
  SalesIncreaseReasonType.OTHER,
];

/**
 * 延迟录入原因
 *
 * @enum
 */
export enum delayEnterReasonType {
  // WEEKEND = '周末用药',
  HOLIDAY = '假期用药',
  OFFLINE = '线下任务',
  CODE_ADDED = '编码新增',
  REENTRY_AFTER_DELETED = '信息删除后补录',
  VIRTUAL = '虚拟录入',
  DELAY = '延迟录入',
  OTHER = '其他',
  NONE = '',
}

export const delayEnterReasonTypes = [
  delayEnterReasonType.HOLIDAY,
  delayEnterReasonType.OFFLINE,
  delayEnterReasonType.CODE_ADDED,
  delayEnterReasonType.REENTRY_AFTER_DELETED,
  delayEnterReasonType.VIRTUAL,
  delayEnterReasonType.DELAY,
  delayEnterReasonType.OTHER,
];

/**
 * 合格 / 不合格状态
 *
 * @enum
 */
export enum QualifiedStatusType {
  QUALIFIED = '合格',
  UNQUALIFIED = '不合格',
  NONE = '',
}

export const QualifiedStatusTypes = [QualifiedStatusType.QUALIFIED, QualifiedStatusType.UNQUALIFIED];

/**
 * 是否自营
 */
export enum AutarkyType {
  SELF_OPERATE = '自营',
  NOT_SELF_OPERATE = '非自营',
}

export const Autarkies = [AutarkyType.SELF_OPERATE, AutarkyType.NOT_SELF_OPERATE];

export const DisqualificationReasonTypes = [
  '处方（病历）未提供',
  '处方（病历）未按期更新',
  '处方（病历）书写不规范',
  '检查报告未提供',
  '检查项目未按期更新',
  '检查项目未按检测指标提供',
  '未见纸质档案',
  '院内购药凭证不合格',
  '商业公司购药',
  '资料或用药造假',
  OPTION_OTHER,
  '虚拟录入',
];

/**
 * 用药时长说明
 */
export enum MedicationDurationExplainType {
  BASE_ON_OUTPATIENT_RECORD = '按照门诊出库录入',
  BASE_ON_MEDICATION_CERT = '按照购药凭证录入',
  BASE_ON_CUSTOMER_SERVICE = '按照客服提供',
  OTHER = '其他',
}

export const MedicationDurationExplainTypes = [
  MedicationDurationExplainType.BASE_ON_OUTPATIENT_RECORD,
  MedicationDurationExplainType.BASE_ON_MEDICATION_CERT,
  MedicationDurationExplainType.BASE_ON_CUSTOMER_SERVICE,
  OPTION_OTHER,
];

/**
 * 购药机构类型
 *
 * @enum
 */
export enum HospitalType {
  RESIDENT_COOPERATIVE_CLINIC = '常驻合作门诊',
  NON_RESIDENT_COOPERATIVE_CLINIC = '非常驻合作门诊',
  PRIVATE_HOSPITAL = '民营医院',
  PUBLIC_HOSPITAL = '公立医院',
}

export const HospitalTypes = [
  HospitalType.RESIDENT_COOPERATIVE_CLINIC,
  HospitalType.NON_RESIDENT_COOPERATIVE_CLINIC,
  HospitalType.PUBLIC_HOSPITAL,
  HospitalType.PRIVATE_HOSPITAL,
];

/**
 * 注射剂量单位
 *
 * @enum
 */
export enum InjectionUnitType {
  IU_PER_DAY = 'IU/d',
  MG_PER_WEEK = 'mg/w',
  IU_PER_WEEK = 'IU/w',
}

/**
 * 单位用药剂量状态
 *
 * @enum
 */
export enum UnitDoseStatusType {
  BASE_ON_HOSPITAL_PRESCRIPTION = '按照院内处方录入',
  BASE_ON_OUTPATIENT_PRESCRIPTION = '按照门诊处方录入',
  BASE_ON_CUSTOMER_SERVICE = '按照客服提供录入',
  BASE_ON_PRESCRIPTION_AND_CUSTOMER_SERVICE = '按照处方及客服提供录入',
  VIRTUAL_RECORD = '虚拟录入',
}

export const UnitDoseStatusTypes = [
  UnitDoseStatusType.BASE_ON_HOSPITAL_PRESCRIPTION,
  UnitDoseStatusType.BASE_ON_OUTPATIENT_PRESCRIPTION,
  UnitDoseStatusType.BASE_ON_CUSTOMER_SERVICE,
  UnitDoseStatusType.BASE_ON_PRESCRIPTION_AND_CUSTOMER_SERVICE,
  UnitDoseStatusType.VIRTUAL_RECORD,
];

/**
 * 用药信息表单结构
 */
export interface IFormMedicationDTO<TDateType = string, CheckboxFieldType = string> extends IBaseFormDTO {
  /* 模块名称 */
  moduleName: '用药信息';

  /* 是否修改进销存点位 */
  modifyInventoryInfo?: boolean;

  /* 库存修改记录ID */
  inventoryNumRecordId: string;

  /* 就诊省份ID */
  visitDoctorProvinceId: string;

  /* 就诊省份 */
  visitDoctorProvinceName: string;

  /* 就诊机构类型 */
  hospitalType: string;

  /* 就诊机构ID */
  hospitalId: string;

  /* 就诊机构名称 */
  hospitalName: string;

  /* 就诊医生ID */
  doctorId: string;

  /* 就诊医生 */
  doctorName: string;

  /* 处方日期, YYYY-MM-DD */
  prescriptionDate: WithNull<TDateType>;

  /* 处方有效期，YYYY-MM-DD */
  prescriptionValidity: WithNull<TDateType>;

  /* 检查报告单日期, YYYY-MM-DD */
  inspectionReportDate: WithNull<TDateType>;

  /* 检查报告单有效期，YYYY-MM-DD */
  inspectionReportValidity: WithNull<TDateType>;

  /* 购药省份ID */
  buyMedicineProvinceId: string;

  /* 购药省份 */
  buyMedicineProvinceName: string;

  /* 购药机构类型 */
  buyMedicineMechanismType: string;

  /* 是否自营 */
  autarky: string;

  /* 购药机构ID */
  buyMedicineMechanismId: string;

  /* 购药机构名称 */
  buyMedicineMechanismName: string;

  /* 购药时间，YYYY-MM-DD */
  buyMedicineDate: WithNull<TDateType>;

  /* 用药出现时间,YYYY-MM-DD */
  // salesIncreaseDate: WithNull<TDateType>;

  /* 用药出现原因 */
  salesIncreaseReason: SalesIncreaseReasonType;

  /* 用药出现原因(其他，请描述) */
  otherDesc: string;

  /* 规格ID */
  specificationsId: string;

  /* 规格名称 */
  specificationsName: string;

  /* 批号 */
  batchNumber: string;

  /* 发货价(元/支) */
  deliveryPrice: string;

  /* 实际出库价(元/支) */
  actualDeliveryPrice: string;

  /* 批号结余库存 */
  batchBalanceInventory: string;

  /* 购药数量（支） */
  drugPurchasedNumber: string;

  /* 退药数量（支） */
  drugWithdrawalNumber: string;

  /* 实际数量（支） */
  actualNumber: string;

  /* 注射剂量（mg/w或IU/d） */
  injectionDose: string;

  /* 注射剂量单位 */
  injectionUnit: string;

  /* 打 */
  hit: string;

  /* 停 */
  stop: string;

  /* 体重（KG） */
  weight: string;
  /* 确诊体重（KG） */
  visitWeight: string;

  /* 用药时长显示（天） */
  medicationDuration: string;

  /* 用药时长说明 */
  medicationDurationExplain: string;

  /* 用药时长说明备注(其他，请描述) */
  medicationDurationExplainRemark: string;

  /* 单位用药剂量(IU/KG) */
  unitDose: string;

  /* 单位用药剂量状态 */
  unitDoseStatus: string;

  /* 预计下次购药数量（支） */
  estimateNextPurchaseQuantity: string;

  /* 状态标识  合格/不合格 */
  statusIdentification: QualifiedStatusType;

  /* 不合格原因 */
  disqualificationReason: CheckboxFieldType;

  /* 不合格原因备注(其他，请描述) */
  nextPurchaseOtherDesc: string;

  /* 是否赠药 */
  isFreeMedicine: string;

  /* 客服备注 */
  drugCustomerRemark: string;

  /* 备注 */
  drugRemark: string;

  /* 展示用药出现原因或延迟录入原因 */
  delayOrSale: 'SALE' | 'DELAY';

  /* 获取的排除节假日后的超时时间，用来判断delayOrSale */
  delayTime: string;

  /* 延迟录入原因 */
  delayEnterReason: delayEnterReasonType;

  /* 延迟录入原因(其他，请描述) */
  delayEnterReasonOther: string;

  /** 预计下次购药时间 */
  nextPurchaseTime: string;
  /** 用药信息创建时间 */
  createDate: any;
  /** 不合格原因:门诊/医院 */
  statuSnonconformity: string;
  materialIrregularities: any;
}
