import {
  IFormMedicationDTO,
  QualifiedStatusType,
  SalesIncreaseReasonType,
  delayEnterReasonType,
  AutarkyType,
  HospitalType,
} from '@/type/formMedicationDTO';
import { convert2MomentForm, deserializationMomentForm, genDefaultBaseFormDTO } from '@/views/form/utils';
import { compact } from 'lodash';
import moment from 'moment';
import { Modal } from 'ant-design-vue';

export const convertMedicationForm = (
  form: IFormMedicationDTO<string, string>,
): IFormMedicationDTO<moment.Moment, string[]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const momentForm: any = convert2MomentForm(form, [
    'prescriptionDate',
    'prescriptionValidity',
    'inspectionReportDate',
    'inspectionReportValidity',
    'buyMedicineDate',
    // 'salesIncreaseDate',
  ]);

  momentForm.disqualificationReason = compact((momentForm.disqualificationReason || '').split(','));

  return momentForm;
};

export const deserializationMedicationForm = (
  form: IFormMedicationDTO<moment.Moment, string[]>,
): IFormMedicationDTO<string, string> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deserializationForm: any = deserializationMomentForm(form, [
    { field: 'prescriptionDate' },
    { field: 'prescriptionValidity' },
    { field: 'inspectionReportDate' },
    { field: 'inspectionReportValidity' },
    { field: 'buyMedicineDate' },
    // { field: 'salesIncreaseDate' },
  ]);
  if (form.statusIdentification == '合格') {
    deserializationForm.disqualificationReason = '';
  } else {
    // 为true新数据,false的是老数据，老数据用之前的逻辑和ui
    const type = form.statuSnonconformity == '1' ? '门诊' : form.statuSnonconformity == '2' ? '医院' : '';
    if (type) {
      deserializationForm.disqualificationReason = `${type}：` + (form.disqualificationReason as string[]).join(',');
    } else {
      deserializationForm.disqualificationReason = (form.disqualificationReason as string[]).join(',');
    }
    // 点击暂存/提交，可以将数据变成老数据
    // deserializationForm.disqualificationReason = '处方（病历）未提供'
  }
  return deserializationForm;
};

export const genDefaultFormMedicationDTO = (): IFormMedicationDTO<moment.Moment, string[]> => ({
  ...genDefaultBaseFormDTO(),
  /* 模块名称 */
  moduleName: '用药信息',

  /* 是否修改进销存点位 */
  modifyInventoryInfo: undefined,

  /* 库存修改记录ID */
  inventoryNumRecordId: '',

  /* 首诊省份ID */
  visitDoctorProvinceId: '',

  /* 首诊省份 */
  visitDoctorProvinceName: '',

  /* 首诊机构类型 */
  hospitalType: '',

  /* 首诊机构ID */
  hospitalId: '',

  /* 首诊机构名称 */
  hospitalName: '',

  /* 首诊医生ID */
  doctorId: '',

  /* 首诊医生 */
  doctorName: '',

  /* 处方日期, YYYY-MM-DD */
  prescriptionDate: null,

  /* 处方有效期，YYYY-MM-DD */
  prescriptionValidity: null,

  /* 检查报告单日期, YYYY-MM-DD */
  inspectionReportDate: null,

  /* 检查报告单有效期，YYYY-MM-DD */
  inspectionReportValidity: null,

  /* 购药省份ID */
  buyMedicineProvinceId: '',

  /* 购药省份 */
  buyMedicineProvinceName: '',

  /* 购药机构类型 */
  buyMedicineMechanismType: '',

  /* 购药机构ID */
  buyMedicineMechanismId: '',

  /* 购药机构名称 */
  buyMedicineMechanismName: '',

  /* 购药时间，YYYY-MM-DD */
  buyMedicineDate: null,

  /* 用药出现时间,YYYY-MM-DD */
  // salesIncreaseDate: null,

  /* 用药出现原因 */
  salesIncreaseReason: SalesIncreaseReasonType.REGULAR_OFFER,

  /* 用药出现原因(其他，请描述) */
  otherDesc: '',

  /* 延迟录入原因 */
  delayEnterReason: delayEnterReasonType.NONE,

  /* 延迟录入原因(其他，请描述) */
  delayEnterReasonOther: '',

  /* 规格ID */
  specificationsId: '',

  /* 规格 */
  specificationsName: '',

  /* 批号 */
  batchNumber: '',

  /* 发货价(元/支) */
  deliveryPrice: '',

  /* 实际出库价(元/支) */
  actualDeliveryPrice: '',

  /* 批号结余库存 */
  batchBalanceInventory: '',

  /* 购药数量（支） */
  drugPurchasedNumber: '',

  /* 退药数量（支） */
  drugWithdrawalNumber: '',

  /* 实际数量（支） */
  actualNumber: '',

  /* 注射剂量（mg/w或IU/d） */
  injectionDose: '',

  /* 打 */
  hit: '',

  /* 停 */
  stop: '',

  /* 体重（KG） */
  weight: '',
  /* 确诊体重（KG） */
  visitWeight: '',
  /* 用药时长显示（天） */
  medicationDuration: '',

  /* 用药时长说明 */
  medicationDurationExplain: '',

  /* 用药时长说明(其他，请描述) */
  medicationDurationExplainRemark: '',

  /* 单位用药剂量(IU/KG) */
  unitDose: '',

  /* 单位用药剂量状态 */
  unitDoseStatus: '',

  /* 预计下次购药数量（支） */
  estimateNextPurchaseQuantity: '',

  /* 状态标识  合格/不合格 */
  statusIdentification: QualifiedStatusType.NONE,

  /* 不合格原因 */
  disqualificationReason: [],

  /* 不合格原因(其他，请描述) */
  nextPurchaseOtherDesc: '',

  /* 是否赠药 */
  isFreeMedicine: '',

  /* 客服备注 */
  drugCustomerRemark: '',

  /* 备注 */
  drugRemark: '',

  /* 是否自营 */
  autarky: '',

  /* 注射剂量单位 */
  injectionUnit: '',

  /* 展示用药出现原因或延迟录入原因 */
  delayOrSale: 'SALE',

  /* 获取的排除节假日后的超时时间，用来判断delayOrSale */
  delayTime: '',
  /** 预计下次购药时间 */
  nextPurchaseTime: '',
  /** 用药信息创建时间 */
  createDate: '',
  /** 不合格原因:门诊/医院 */
  statuSnonconformity: '',
  materialIrregularities: '',
});

//自营非自营校验弹窗
const checkAutarkyModal = (autarky: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title: '是否继续提交',
      content: `您的该笔用药应为${autarky}，请确认无误后提交！`,
      onOk: () => {
        return resolve(true);
      },
      onCancel: () => {
        return reject(false);
      },
    });
  });
};

/**检验自营非自营弹窗
 * 首单不校验
 * <1>如果首单为【非自营】，非首单为【自营】，则弹出提示窗【您的该笔用药应为非自营，请确认无误后提交！】；
 * <2>如果首单为【自营】，非首单为【自营】，且【购药机构类型】不为【常驻合作门诊】或【非常驻合作门诊】，则弹出提示窗【您的该笔用药应为非自营，请确认无误后提交！】；
 * <3>如果首单为【自营】，非首单为【自营】，且【就诊机构名称】和【购药机构名称】不一致，则弹出提示窗【您的该笔用药应为非自营，请确认无误后提交！】；
 * <4>如果首单为【自营】，非首单为【自营】，且【就诊机构名称】或【购药机构名称】与首单的【就诊机构名称】或【购药机构名称】不一致，则弹出提示窗【您的该笔用药应为非自营，请确认无误后提交！】；
 * <5>如果首单为【自营】，非首单为【非自营】，【购药机构类型】为【常驻合作门诊】或【非常驻合作门诊】，且【就诊机构名称】和【购药机构名称】一致，则弹出提示窗【您的该笔用药应为自营，请确认无误后提交！】；
 * 所有弹窗提示点击确定后均不阻碍提交
 */
export const checkAutarky = (
  fisrtDetail: IFormMedicationDTO<moment.Moment, string[]>,
  formValue: IFormMedicationDTO<moment.Moment, string[]>,
): Promise<boolean> => {
  if (
    (fisrtDetail.autarky === AutarkyType.NOT_SELF_OPERATE ||
      (fisrtDetail.buyMedicineMechanismType !== HospitalType.RESIDENT_COOPERATIVE_CLINIC &&
        fisrtDetail.buyMedicineMechanismType !== HospitalType.NON_RESIDENT_COOPERATIVE_CLINIC)) &&
    formValue.autarky !== AutarkyType.NOT_SELF_OPERATE
  ) {
    return checkAutarkyModal('非自营');
  }
  if (fisrtDetail.autarky === AutarkyType.SELF_OPERATE) {
    if (
      (fisrtDetail.hospitalId !== fisrtDetail.buyMedicineMechanismId ||
        fisrtDetail.hospitalId !== formValue.hospitalId ||
        fisrtDetail.buyMedicineMechanismId !== formValue.buyMedicineMechanismId) &&
      formValue.autarky !== AutarkyType.NOT_SELF_OPERATE
    ) {
      return checkAutarkyModal('非自营');
    } else if (
      fisrtDetail.hospitalId === formValue.hospitalId &&
      fisrtDetail.buyMedicineMechanismId === formValue.buyMedicineMechanismId &&
      formValue.autarky !== AutarkyType.SELF_OPERATE
    ) {
      return checkAutarkyModal('自营');
    }
  }
  return Promise.resolve(true);
};

/**
 * 自营非自营联动赋默认值
 * 首单不校验
 * <1>如果【购药机构类型】不为【常驻合作门诊】或【非常驻合作门诊】，则默认值改为【非自营】；
 * <2>如果【就诊机构名称】和【购药机构名称】不一致，则默认值改为【非自营】；
 * <3>如果【就诊机构名称】或【购药机构名称】与首单的【就诊机构名称】或【购药机构名称】不一致，则默认值改为【非自营】；
 * <4>如果首单为【自营】且，【购药机构类型】为【常驻合作门诊】或【非常驻合作门诊】，且【就诊机构名称】和【购药机构名称】一致，，则默认值改为【自营】；
 */
export const computedAutarky = (
  fisrtDetail: IFormMedicationDTO<moment.Moment, string[]> | null,
  modelRefSubjectFormId: string,
  modelRefBuyMedicineMechanismType: string,
  modelRefHospitalId: string,
  modelRefBuyMedicineMechanismId: string,
): string | void => {
  if (fisrtDetail && fisrtDetail.subjectFormId !== modelRefSubjectFormId) {
    if (
      fisrtDetail.autarky === AutarkyType.SELF_OPERATE &&
      (modelRefBuyMedicineMechanismType === HospitalType.RESIDENT_COOPERATIVE_CLINIC ||
        modelRefBuyMedicineMechanismType === HospitalType.NON_RESIDENT_COOPERATIVE_CLINIC) &&
      modelRefHospitalId === modelRefBuyMedicineMechanismId &&
      modelRefHospitalId === fisrtDetail.hospitalId
    ) {
      return AutarkyType.SELF_OPERATE;
    } else {
      return AutarkyType.NOT_SELF_OPERATE;
    }
  }
};
