/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { uniqueId } from 'lodash';

import { IFormMedicationDTO } from '@/type/formMedicationDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';

import {
  isShowAutarky,
  isShowDisqualificationReasonType,
  isShowMedicationDurationExplain,
  isShowMedicationDurationExplainRemark,
  isShowNextPurchaseOtherDesc,
  // isShowOtherDesc,
  isShowDelayEnterReasonOther,
  isShowSalesIncreaseReason,
  isShowDelayEnterReason,
  isShowDrugCustomerRemark,
} from './optionModel';
import UnitDoseHelper from './itemHelpers/UnitDoseHelper';
import { formatDateWithNone } from '../../utils';

const previewConfig: IFormPreviewGroup<IFormMedicationDTO>[] = [
  {
    id: uniqueId('MedicationForm1'),
    groupName: '处方信息',
    items: [
      { label: '就诊省份', fieldName: 'visitDoctorProvinceName' },
      { label: '就诊机构类型', fieldName: 'hospitalType' },
      { label: '就诊机构名称', fieldName: 'hospitalName' },
      { label: '就诊KH', fieldName: 'doctorName' },
      {
        label: '处方日期',
        fieldName: 'prescriptionDate',
        format: (val) => formatDateWithNone(val),
      },
      {
        label: '处方有效期',
        fieldName: 'prescriptionValidity',
        format: (val) => formatDateWithNone(val),
      },
      {
        label: '检查报告单日期',
        fieldName: 'inspectionReportDate',
        format: (val) => formatDateWithNone(val),
      },
      {
        label: '检查报告单有效期',
        fieldName: 'inspectionReportValidity',
        format: (val) => formatDateWithNone(val),
      },
    ],
  },
  {
    id: uniqueId('MedicationForm2'),
    groupName: '购药信息',
    items: [
      { label: '购药省份', fieldName: 'buyMedicineProvinceName' },
      { label: '购药机构类型', fieldName: 'buyMedicineMechanismType' },
      {
        label: '是否自营',
        fieldName: 'autarky',
        isShow: isShowAutarky,
      },
      { label: '购药机构名称', fieldName: 'buyMedicineMechanismName' },
      {
        label: '购药时间',
        fieldName: 'buyMedicineDate',
        format: (val) => formatDateWithNone(val),
      },
      // {
      //   label: '用药出现时间',
      //   fieldName: 'salesIncreaseDate',
      //   format: (val) => formatDateWithNone(val),
      // },
      { label: '用药出现原因', fieldName: 'salesIncreaseReason', isShow: isShowSalesIncreaseReason },
      { label: '延迟录入原因', fieldName: 'delayEnterReason', isShow: isShowDelayEnterReason },
      // { label: '其他，请描述', fieldName: 'otherDesc', isShow: isShowOtherDesc },
      { label: '其他，请描述', fieldName: 'delayEnterReasonOther', isShow: isShowDelayEnterReasonOther },
      { label: '规格', fieldName: 'specificationsName' },
      { label: '批号', fieldName: 'batchNumber' },
      { label: '发货价(元/支)', fieldName: 'deliveryPrice' },
      { label: '实际出库价(元/支)', fieldName: 'actualDeliveryPrice' },
      { label: '批号结余库存', fieldName: 'batchBalanceInventory' },
      { label: '购药数量(支)', fieldName: 'drugPurchasedNumber' },
      { label: '退药数量(支)', fieldName: 'drugWithdrawalNumber' },
      { label: '实际数量(支)', fieldName: 'actualNumber' },
    ],
  },
  {
    id: uniqueId('MedicationForm3'),
    groupName: '剂量信息',
    items: [
      { label: '注射剂量', fieldName: 'injectionDose' },
      { label: '注射剂量单位', fieldName: 'injectionUnit' },
      { label: '打', fieldName: 'hit' },
      { label: '停', fieldName: 'stop' },
      { label: '体重(KG)', fieldName: 'weight' },
      { label: '用药时长显示(天)', fieldName: 'medicationDuration' },
      { label: '用药时长说明', fieldName: 'medicationDurationExplain', isShow: isShowMedicationDurationExplain },
      {
        label: '其他，请描述',
        fieldName: 'medicationDurationExplainRemark',
        isShow: isShowMedicationDurationExplainRemark,
      },
      { label: '单位用药剂量(IU/KG)', fieldName: 'unitDose' },
      {
        label: '单位用药剂量状态',
        fieldName: 'unitDoseStatus',
        isShow: (formValue: IFormMedicationDTO<any, any>) => {
          return UnitDoseHelper.hasDoubt(formValue);
        },
      },
    ],
  },
  {
    id: uniqueId('MedicationForm4'),
    groupName: '预计下次购药信息',
    items: [
      { label: '预计下次购药数量(支)', fieldName: 'estimateNextPurchaseQuantity' },
      { label: '预计下次购药时间', fieldName: 'nextPurchaseTime' },
    ],
  },
  {
    id: uniqueId('MedicationForm5'),
    groupName: '其他信息',
    items: [
      { label: '资料不规范情况', fieldName: 'materialIrregularitiesStr' },
      { label: '购药不规范项', fieldName: 'drugIrregularitiesStr' },
      { label: '状态标识', fieldName: 'statusIdentification' },
      { label: '不合格原因', fieldName: 'disqualificationReason', isShow: isShowDisqualificationReasonType },
      { label: '其他，请描述', fieldName: 'nextPurchaseOtherDesc', isShow: isShowNextPurchaseOtherDesc },
      { label: '是否优惠购药', fieldName: 'isDiscount' },
      { label: '优惠情况', fieldName: 'discount' },
      { label: '是否赠药', fieldName: 'isFreeMedicine' },
      { label: '客服备注', fieldName: 'drugCustomerRemark', isShow: isShowDrugCustomerRemark },
      { label: '备注', fieldName: 'drugRemark' },
    ],
  },
];

export default previewConfig;
