
  import { computed, defineComponent, PropType, reactive, ref, toRaw, Ref, inject } from 'vue';
  import { cloneDeep } from 'lodash';

  import { DepartmentLabelType, IFormTreatmentDTO, IndicationGroupType, IndicationType } from '@/type/formTreatmentDTO';
  import dictionary, { filterAllRegion } from '@/lib/dictionary';
  import { DictionaryTypeEnum, DictionaryVo } from '@/type/api';

  import {
    computedLabeledValueModel,
    handePopupContainerByClass,
    handleFilterOptionCaseInsensitive,
  } from '../../utils';

  import {
    convertTreatmentForm,
    // domainLabels,
    // departmentLabels,
    heightPs,
    deserializationTreatmentForm,
  } from './utils';
  import EffectHandler from './effectHandler';
  import genRules from './rules';
  import {
    genIndicationModel,
    genIndicationGroupModel,
    genVisitedDoctorModel,
    genVisitedHospitalModel,
    genVisitedCityModel,
    genVisitedProvinceModel,
    genVisitWeightDoubt,
    genVisitHeightDoubt,
    genTreatmentIrregularitiesModel,
  } from './optionModel';
  import { IFormSubmitCallback, ValidateFailedCallback } from '../../interface';
  import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
  import { Moment } from 'moment';

  export default defineComponent({
    name: 'treatment-form',
    props: {
      formValue: {
        type: Object as PropType<IFormTreatmentDTO<string>>,
        default: null,
      },
    },
    setup(props) {
      const loading = ref(false);
      const handleLoadingChange = (val: boolean) => (loading.value = val);

      const modelRef = reactive(cloneDeep(toRaw(convertTreatmentForm(props.formValue)))) as IFormTreatmentDTO<Moment>;
      const formRef = ref();

      const effectHandler = EffectHandler(modelRef);
      const [indicationModel, indications, handleIndicationChange] = genIndicationModel(modelRef, effectHandler);
      const [indicationGroupModel, indicationGroups, handleIndicationGroupChange, domainLabels, departmentLabels] =
        genIndicationGroupModel(modelRef, effectHandler, indications, handleLoadingChange);
      const showAGHDRelated = computed(() =>
        [IndicationGroupType.AGHD].includes(modelRef.indicationGroupId as IndicationGroupType),
      );
      const showCGHDRelated = computed(() =>
        [IndicationGroupType.CGHD].includes(modelRef.indicationGroupId as IndicationGroupType),
      );
      const showFKHDRelated = computed(() =>
        [IndicationGroupType.FKHD].includes(modelRef.indicationGroupId as IndicationGroupType),
      );
      const showDepartmentsOtherRemark = computed(
        () => showAGHDRelated.value && modelRef.departmentLabel === DepartmentLabelType.OTHERS,
      );
      const showDepartmentLabel = computed(() => {
        console.log(showCGHDRelated.value && modelRef.domainLabel === '肾科');

        return showCGHDRelated.value && modelRef.domainLabel === '肾科';
      });
      const showIndicationRemark = computed(() => {
        return [
          IndicationType.ORHER_CHILDREN,
          IndicationType.OTHER_ADULT,
          IndicationType.OTHER_SURGICAL_GROUP,
        ].includes(modelRef.indicationId as IndicationType);
      });
      const [visitedDoctorModel, visitedDoctors] = genVisitedDoctorModel(modelRef);
      const [visitedHospitalModel, visitedHospitals, handleHospitalChange] = genVisitedHospitalModel(
        modelRef,
        effectHandler,
        visitedDoctors,
        handleLoadingChange,
      );
      const [visitedCityModel, visitedCities, handleCityChange] = genVisitedCityModel(
        modelRef,
        effectHandler,
        visitedDoctors,
        visitedHospitals,
        handleLoadingChange,
      );
      const [visitedProvinceModel, visitedProvinces, handleProvinceChange] = genVisitedProvinceModel(
        modelRef,
        effectHandler,
        visitedDoctors,
        visitedHospitals,
        visitedCities,
        handleLoadingChange,
      );

      const [
        treatmentIrregularitiesOptions,
        treatmentIrregularitiesCheckAll,
        treatmentIrregularitiesIndeterminate,
        handleCheckAllTreatIrreChange,
        handleCheckSingleTreatIrreChange,
      ] = genTreatmentIrregularitiesModel(modelRef);

      const receptionistModel = computedLabeledValueModel(modelRef, {
        idField: 'receptionistId',
        nameField: 'receptionist',
      });
      const heightManagerModel = computedLabeledValueModel(modelRef, {
        idField: 'heightManagerId',
        nameField: 'heightManager',
      });
      const odyUsers = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

      const rules = genRules(
        showIndicationRemark,
        showAGHDRelated,
        showCGHDRelated,
        showFKHDRelated,
        showDepartmentsOtherRemark,
        showDepartmentLabel,
      );

      /**
       * 用于处理患者群体详情中id缺失的情况
       * 当indicationGroupId为空但indicationGroupName存在值时, 认定为id缺失的情况
       * 此时从字典列表中匹配名称相同的字典项, 并取其jinsaiId作为indicationGroupId
       */
      const handleIndicationGroupsMissingId = (list: DictionaryVo<DictionaryTypeEnum.IndicationGroup>[]) => {
        if (!modelRef.indicationGroupId && modelRef.indicationGroupName) {
          const matchedItem = (list || []).find((item) => item.name === modelRef.indicationGroupName);

          if (!matchedItem) {
            return;
          }

          modelRef.indicationGroupId = matchedItem.jinsaiId;
        }
      };

      const fetchUnchangableList = async () => {
        handleLoadingChange(true);

        const [_visitedProvinces, _indicationGroups, _odyUsers] = await Promise.all([
          dictionary.getStaticDictionary({ type: DictionaryTypeEnum.Province }),
          dictionary.getCurDictionaryWithDefault({ type: DictionaryTypeEnum.IndicationGroup }),
          dictionary.getCurDictionaryWithDefault({ type: DictionaryTypeEnum.OdyUser }),
        ]);

        visitedProvinces.value = filterAllRegion(_visitedProvinces, DictionaryTypeEnum.Province);
        indicationGroups.value = _indicationGroups;
        odyUsers.value = _odyUsers;

        handleIndicationGroupsMissingId(_indicationGroups);
        fetchLists();

        handleLoadingChange(false);
      };

      const fetchLists = async () => {
        handleLoadingChange(true);

        if (modelRef.indicationGroupId) {
          handleIndicationGroupChange(indicationGroupModel.value, true);
        }

        if (modelRef.visitedProvinceId) {
          handleProvinceChange(visitedProvinceModel.value, true);
        }

        if (modelRef.visitedCityId) {
          handleCityChange(visitedCityModel.value, true);
        }

        if (modelRef.visitedHospitalId) {
          handleHospitalChange(visitedHospitalModel.value, true);
        }

        handleLoadingChange(false);
      };

      fetchUnchangableList();

      const getFormValue = () => {
        return deserializationTreatmentForm(cloneDeep(toRaw(modelRef)));
      };

      const handleSubmit = async (
        cb: IFormSubmitCallback,
        validateFailedCb: ValidateFailedCallback<ValidateErrorEntity<IFormTreatmentDTO<Moment>>>,
      ) => {
        formRef.value
          .validate()
          .then(() => {
            cb(getFormValue());
          })
          .catch((err: ValidateErrorEntity<IFormTreatmentDTO<Moment>>) => {
            validateFailedCb(err);
          });
      };
      const handleDomainLabelChange = () => {
        if (!showDepartmentLabel.value) {
          modelRef.departmentLabel = '';
        }
      };
      return {
        handleDomainLabelChange,
        modelRef,
        formRef,
        rules,
        showDepartmentLabel,
        indicationModel,
        indications,
        handleIndicationChange,

        indicationGroupModel,
        indicationGroups,
        handleIndicationGroupChange,
        showAGHDRelated,
        showCGHDRelated,
        showFKHDRelated,
        showDepartmentsOtherRemark,
        showIndicationRemark,
        visitedDoctorModel,
        visitedDoctors,
        visitedHospitalModel,
        visitedHospitals,
        handleHospitalChange,
        visitedCityModel,
        visitedCities,
        handleCityChange,
        visitedProvinceModel,
        visitedProvinces,
        handleProvinceChange,
        receptionistModel,
        heightManagerModel,
        odyUsers,
        domainLabels,
        departmentLabels,
        heightPs,
        getFormValue,
        handleSubmit,
        handleFilterOptionCaseInsensitive,
        handlePopupContainer: handePopupContainerByClass('treatment-form'),
        visitWeightDoubt: genVisitWeightDoubt(modelRef),
        visitHeightDoubt: genVisitHeightDoubt(modelRef),
        treatmentIrregularitiesOptions,
        treatmentIrregularitiesCheckAll,
        treatmentIrregularitiesIndeterminate,
        handleCheckAllTreatIrreChange,
        handleCheckSingleTreatIrreChange,
      };
    },
  });
