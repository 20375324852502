/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
import { LabeledValue } from 'ant-design-vue/lib/select';
import { computed, ComputedRef, Ref, ref, WritableComputedRef } from 'vue';

import dictionary, { filterAllRegion } from '@/lib/dictionary';
import { DictionaryTypeEnum, DictionaryVo } from '@/type/api';
import {
  Autarkies,
  DisqualificationReasonTypes,
  HospitalType,
  HospitalTypes,
  IFormMedicationDTO,
  InjectionUnitType,
  QualifiedStatusType,
  SalesIncreaseReasonType,
  delayEnterReasonType,
} from '@/type/formMedicationDTO';

import { computedLabeledValueModel, computedStringModel, EmptyAsyncFunc, EMPTY_DOUBT, OPTION_OTHER } from '../../utils';
import { MedicationFormEffectHandler } from './interface';
import { IFormDoubt, LoadingHandler, ModelGeneratorReturnType } from '../../interface';
import moment, { Moment } from 'moment';
import { getBatchCode, getInventoryNum, getPrice, getHospitalLabelInfo, getSysConfigInfo } from '@/services/form';
import UnitDoseHelper from './itemHelpers/UnitDoseHelper';
import WeightHelper from './itemHelpers/WeightHelper';
import PrescriptionValidityHelper from './itemHelpers/PrescriptionValidityHelper';
import InspectionReportValidityHelper from './itemHelpers/InspectionReportValidityHelper';
import MedicationDurationHelper from './itemHelpers/MedicationDurationHelper';
import InjectionDoseHelper from './itemHelpers/InjectionDoseHelper';
import DrugPurchasedNumberHelper from './itemHelpers/DrugPurchasedNumberHelper';
import DrugWithdrawalNumberHelper from './itemHelpers/DrugWithdrawalNumberHelper';
import buyMedicineDateDoubtHelper from './itemHelpers/buyMedicineDateDoubtHelper';
import injectionUnitDoubtHelper from './itemHelpers/injectionUnitDoubtHelper';
import FrequencyHelper from './itemHelpers/FrequencyHelper';
import ActualDeliveryPriceHelper from './itemHelpers/ActualDeliveryPriceHelper';
import { IFormMethod } from '@/type/form';

/**
 * 生成就诊医生model, 就诊医生列表
 *
 * @param modelRef
 * @returns {ModelGeneratorReturnType}
 */
export const genDoctorModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  formRef: Ref<IFormMethod>,
): ModelGeneratorReturnType => {
  const doctorModel = computedLabeledValueModel(modelRef, {
    idField: 'doctorId',
    nameField: 'doctorName',
  });

  const doctors = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  (async () => {
    if (modelRef.hospitalId) {
      doctors.value = await dictionary.getCurDictionaryWithDefault({
        type: DictionaryTypeEnum.Doctor,
        hospitalId: modelRef.hospitalId,
      });
      // 页面进入就触发“就诊KH不存在”的校验
      formRef.value?.validateFields(['doctorId']);
    }
  })();

  return [doctorModel, doctors, EmptyAsyncFunc];
};

/**
 * 获取医院标签
 */
export const genHospitalLableInfo = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  loadingHandler?: LoadingHandler,
) => {
  const hospitalLableInfo = ref<any>({});
  (async () => {
    if (modelRef.buyMedicineMechanismId) {
      loadingHandler && loadingHandler(true);
      hospitalLableInfo.value = await getHospitalLabelInfo({
        hospitalId: modelRef.buyMedicineMechanismId,
      });
      loadingHandler && loadingHandler(false);
    }
  })();
  return hospitalLableInfo;
};

/**
 * 生成就诊机构model
 *
 * @param modelRef
 * @param effectHandler
 * @param doctors
 * @param loadingHandler
 * @returns {ModelGeneratorReturnType}
 */
export const genHospitalModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  doctors: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType => {
  const hospitalModel = computedLabeledValueModel(modelRef, {
    idField: 'hospitalId',
    nameField: 'hospitalName',
  });

  const hospitals = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  (async () => {
    if (modelRef.visitDoctorProvinceId && modelRef.hospitalType) {
      loadingHandler && loadingHandler(true);

      hospitals.value = await dictionary.getCurDictionaryWithDefault({
        type: DictionaryTypeEnum.Hospital,
        provinceId: modelRef.visitDoctorProvinceId,
        hospitalType: modelRef.hospitalType,
      });

      loadingHandler && loadingHandler(false);
    }
  })();

  const handleHospitalChange = async (val: LabeledValue) => {
    loadingHandler && loadingHandler(true);

    effectHandler.doctorId();
    doctors.value = await dictionary.getCurDictionaryWithDefault({
      type: DictionaryTypeEnum.Doctor,
      hospitalId: val.key,
    });
    loadingHandler && loadingHandler(false);
  };

  return [hospitalModel, hospitals, handleHospitalChange];
};

/**
 * 生成就诊省份model
 *
 * @param modelRef
 * @param effectHandler
 * @param doctors
 * @param hospitals
 * @param loadingHandler
 * @returns {ModelGeneratorReturnType}
 */
export const genVisitDoctorProvince = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  doctors: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  hospitals: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType => {
  const visitDoctorProvinceModel = computedLabeledValueModel(modelRef, {
    idField: 'visitDoctorProvinceId',
    nameField: 'visitDoctorProvinceName',
  });

  const visitDoctorProvinces = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  (async () => {
    visitDoctorProvinces.value = filterAllRegion(
      await dictionary.getCurDictionaryWithDefault({
        type: DictionaryTypeEnum.Province,
      }),
      DictionaryTypeEnum.Province,
    );
  })();

  const handleVisitDoctorProvinceChange = async (val: LabeledValue) => {
    loadingHandler && loadingHandler(true);

    doctors.value = [];
    effectHandler.visitDoctorProvinceId();

    if (modelRef.hospitalType) {
      hospitals.value = await dictionary.getCurDictionaryWithDefault({
        type: DictionaryTypeEnum.Hospital,
        provinceId: val.value as string,
        hospitalType: modelRef.hospitalType,
      });
    } else {
      hospitals.value = [];
    }

    loadingHandler && loadingHandler(false);
  };

  return [visitDoctorProvinceModel, visitDoctorProvinces, handleVisitDoctorProvinceChange];
};

/**
 * 生成就诊机构类型model
 *
 * @param modelRef
 * @param effectHandler
 * @param doctors
 * @param hospitals
 * @param loadingHandler
 * @returns {ModelGeneratorReturnType}
 */
export const genHospitalTypeModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  doctors: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  hospitals: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType<string, string[]> => {
  const hospitalTypeModel = computedStringModel(modelRef, 'hospitalType');

  const hospitalTypes = ref<string[]>(HospitalTypes);

  const handleHospitalTypeChange = async (val: string) => {
    loadingHandler && loadingHandler(true);

    doctors.value = [];
    effectHandler.hospitalType();

    if (modelRef.visitDoctorProvinceId) {
      hospitals.value = await dictionary.getCurDictionaryWithDefault({
        type: DictionaryTypeEnum.Hospital,
        provinceId: modelRef.visitDoctorProvinceId as string,
        hospitalType: val,
      });
    } else {
      hospitals.value = [];
    }

    loadingHandler && loadingHandler(false);
  };

  return [hospitalTypeModel, hospitalTypes, handleHospitalTypeChange];
};

/**
 * 禁用今天之后的日期
 *
 * @param {Moment} [curr]
 * @returns {boolean}
 */
export const disabledDateAfterToday = (curr?: Moment) => {
  return curr && curr > moment().endOf('day');
};

/**
 * 生成处方有效期禁用规则
 *
 * @param modelRef
 * @returns {Function}
 */
export const genPrescriptionValidityHandler = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const prescriptionValidityDisabledHandler = (curr?: Moment): boolean => {
    return !!curr && !!modelRef.prescriptionDate && modelRef.prescriptionDate.startOf('day') > curr;
  };

  return prescriptionValidityDisabledHandler;
};

/**
 * 处方有效期
 */
export const genPrescriptionValidityModel = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const prescriptionValidityDoubt = computed<IFormDoubt>(() => {
    const hasFeedback = PrescriptionValidityHelper.hasDoubt(modelRef);

    if (!hasFeedback) {
      return EMPTY_DOUBT;
    }

    return {
      help: PrescriptionValidityHelper.getDoubtMessage(modelRef),
      class: 'form-item-warning',
    };
  });

  return prescriptionValidityDoubt;
};
/**
 * 生成检查报告单有效期禁用规则
 *
 * @param modelRef
 * @returns {Function}
 */
export const genInspectionReportValidityHandler = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const inspectionReportValidityDisabledHandler = (curr?: Moment) => {
    return !!curr && !!modelRef.inspectionReportDate && modelRef.inspectionReportDate.startOf('day') > curr;
  };

  return inspectionReportValidityDisabledHandler;
};
/**
 * 检查报告单有效期
 */
export const genInspectionReportValidityModel = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const inspectionReportValidityDoubt = computed<IFormDoubt>(() => {
    const hasFeedback = InspectionReportValidityHelper.hasDoubt(modelRef);

    if (!hasFeedback) {
      return EMPTY_DOUBT;
    }

    return {
      help: InspectionReportValidityHelper.getDoubtMessage(modelRef),
      class: 'form-item-warning',
    };
  });

  return inspectionReportValidityDoubt;
};
/**
 * 生成用药出现时间禁用规则
 *
 * @param modelRef
 * @returns {Function}
 */
export const genSalesIncreaseDateHandler = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const salesIncreaseDateDisabledHandler = (curr?: Moment) => {
    if (!curr) {
      return false;
    }

    if (curr > moment().endOf('day')) {
      return true;
    }

    return modelRef.buyMedicineDate && modelRef.buyMedicineDate.startOf('day') > curr;
  };

  return salesIncreaseDateDisabledHandler;
};

/**
 * 获取价格列表
 */
const fetchDeliveryPrices = async (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  loadingHandler?: LoadingHandler,
) => {
  let list: string[] = [];

  if (modelRef.buyMedicineMechanismId && modelRef.batchNumber && modelRef.specificationsName) {
    loadingHandler && loadingHandler(true);

    list = await getPrice({
      hospitalId: modelRef.buyMedicineMechanismId,
      spec: modelRef.specificationsName,
      batchCode: modelRef.batchNumber,
    });

    loadingHandler && loadingHandler(false);
  }

  return list;
};

/**
 * 获取批号结余库存
 */
const fetchInventoryNum = async (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  loadingHandler?: LoadingHandler,
) => {
  let inventoryNum = '';

  if (
    modelRef.buyMedicineMechanismId &&
    modelRef.batchNumber &&
    modelRef.specificationsName &&
    modelRef.deliveryPrice
  ) {
    loadingHandler && loadingHandler(true);

    inventoryNum = await getInventoryNum({
      /* 医院ID */
      hospitalId: modelRef.buyMedicineMechanismId,

      /* 规格 */
      spec: modelRef.specificationsName,

      /* 批号 */
      batchCode: modelRef.batchNumber,

      /* 价格 */
      price: modelRef.deliveryPrice,
    });

    loadingHandler && loadingHandler(false);
  }

  return inventoryNum.toString();
};

/**
 * 获取批号列表
 */
const fetchBatchCodes = async (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  loadingHandler?: LoadingHandler,
) => {
  let list: string[] = [];

  if (modelRef.buyMedicineMechanismId && modelRef.specificationsName) {
    loadingHandler && loadingHandler(true);

    list = await getBatchCode({
      hospitalId: modelRef.buyMedicineMechanismId,
      spec: modelRef.specificationsName,
    });

    loadingHandler && loadingHandler(false);
  }

  return list;
};

/**
 * 生成价格列表model
 *
 * @param modelRef
 * @returns {ModelGeneratorReturnType<string, string[]>}
 */
export const genDeliveryPriceModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType<string, string[]> => {
  const deliveryPriceModel = computedStringModel(modelRef, 'deliveryPrice');

  const deliveryPrices = ref<string[]>([]);
  const handleDeliveryPriceChange = async (val: string, init?: boolean) => {
    if (!init) {
      effectHandler.deliveryPrice(val);
    }
    modelRef.batchBalanceInventory = await fetchInventoryNum(modelRef, loadingHandler);
  };
  (async () => {
    // 价格列表
    deliveryPrices.value = await fetchDeliveryPrices(modelRef, loadingHandler);
    // 机构端/返回发货价默认展示第0项(详情里面有值，用详情的，没有的话用列表的)
    if (!modelRef.deliveryPrice && deliveryPrices.value.length) {
      handleDeliveryPriceChange(deliveryPrices.value[0]);
    }
  })();
  return [deliveryPriceModel, deliveryPrices, handleDeliveryPriceChange];
};

export const genDrugPurchasedNumber = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate: Function,
) => {
  const drugPurchasedNumberDoubt = computed<IFormDoubt>(() => {
    const [hasDoubt, doubtMessage] = DrugPurchasedNumberHelper.hasDoubt(modelRef);

    return hasDoubt
      ? {
          help: doubtMessage,
          class: 'form-item-warning',
        }
      : EMPTY_DOUBT;
  });

  const handleDrugPurchasedNumberChange = () => {
    effectHandler.drugPurchasedNumber();
    validate(['drugWithdrawalNumber']);
  };

  return [drugPurchasedNumberDoubt, handleDrugPurchasedNumberChange];
};

export const genDrugWithdrawalNumber = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const drugWithdrawalNumberDoubt = computed<IFormDoubt>(() => {
    const [hasDoubt, doubtMessage] = DrugWithdrawalNumberHelper.hasDoubt(modelRef);

    return hasDoubt
      ? {
          help: doubtMessage,
          class: 'form-item-warning',
        }
      : EMPTY_DOUBT;
  });

  return drugWithdrawalNumberDoubt;
};

// 录入日期(用药信息创建时间)-购药日期＞90天，提示语： 录入日期-购药日期超过90天
// 购药日期-派单日期（用药信息创建时间）＞0，提示语： 购药日期-派单日期＞0
// dictionaryList： 零氪-预警规则参数服务端可配置
export const buyMedicineDateDoubtFun = (modelRef: IFormMedicationDTO<moment.Moment, string[]>, dictionaryList: any) => {
  const drugWithdrawalNumberDoubt = computed<IFormDoubt>(() => {
    const [hasDoubt, doubtMessage] = buyMedicineDateDoubtHelper.hasDoubt(modelRef, dictionaryList);
    return hasDoubt
      ? {
          help: doubtMessage,
          class: 'form-item-warning',
        }
      : EMPTY_DOUBT;
  });

  return drugWithdrawalNumberDoubt;
};

// 如果用药规格为：【GH54IU】/【GH54IU(卡式瓶)】，在 剂量信息 部分中的字段【注射剂量单位】如果选中IU/w时，提示：长效注射单位疑似异常
export const injectionUnitDoubtFun = (modelRef: IFormMedicationDTO<moment.Moment, string[]>, dictionaryList: any) => {
  const drugWithdrawalNumberDoubt = computed<IFormDoubt>(() => {
    const [hasDoubt, doubtMessage] = injectionUnitDoubtHelper.hasDoubt(modelRef, dictionaryList);
    return hasDoubt
      ? {
          help: doubtMessage,
          class: 'form-item-warning',
        }
      : EMPTY_DOUBT;
  });

  return drugWithdrawalNumberDoubt;
};

/**
 * 生成批号model
 *
 * @param modelRef
 * @param effectHandler
 * @param deliveryPrices 发货价列表
 * @param loadingHandler
 * @returns {ModelGeneratorReturnType<string, string[]>}
 */
export const genBatchNumberModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  formRef: Ref<IFormMethod>,
  deliveryPrices: Ref<string[]>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType<string, string[]> => {
  const batchNumberModel = computedStringModel(modelRef, 'batchNumber');

  const batchNumbers = ref<string[]>([]);

  const handleBatchNumberChange = async () => {
    loadingHandler && loadingHandler(true);
    effectHandler.deliveryPrice();

    handleBatchListChange(
      modelRef,
      effectHandler,
      formRef,
      deliveryPrices,
      batchNumbers,
      'deliveryPrice',
      loadingHandler,
    );
  };

  (async () => {
    batchNumbers.value = await fetchBatchCodes(modelRef);

    if (!modelRef.batchNumber && batchNumbers.value.length) {
      modelRef.batchNumber = batchNumbers.value[0];
      handleBatchNumberChange();
    }
    // 页面进入就触发所有的表单校验
    // formRef.value?.validate();
    // 页面进入就触发“批号不存在”的校验
    formRef.value?.validateFields(['batchNumber']);
  })();


  return [batchNumberModel, batchNumbers, handleBatchNumberChange];
};

/**
 * 生成单位用药剂量model
 */
export const genUnitDoseModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  effectHandler: MedicationFormEffectHandler,
  dictionaryList: any,
) => {
  const unitDoseDoubt = computed<IFormDoubt>(() => {
    const hasFeedback = UnitDoseHelper.hasDoubt(modelRef, dictionaryList.value);

    if (!hasFeedback) {
      return EMPTY_DOUBT;
    }

    return {
      help: UnitDoseHelper.getDoubtMessage(modelRef, dictionaryList),
      class: 'form-item-warning',
    };
  });

  return unitDoseDoubt;
};

/**
 * 生成体重model
 */
// dictionaryList： 零氪-预警规则参数服务端可配置
export const genWeightModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  dictionaryList: any
) => {
  const weightDoubt = computed<IFormDoubt>(() => {
    const hasFeedback = WeightHelper.hasDoubt(modelRef, dictionaryList);

    if (!hasFeedback) {
      return EMPTY_DOUBT;
    }

    return {
      help: WeightHelper.getDoubtMessage(modelRef, dictionaryList),
      class: 'form-item-warning',
    };
  });

  const handleWeightChange = async () => {
    effectHandler.unitDose(modelRef);
  };

  return [weightDoubt, handleWeightChange];
};

export const isShowMedicationDurationExplainRemark = (modelRef: IFormMedicationDTO<any, any>) => {
  return modelRef.medicationDurationExplain === OPTION_OTHER;
};

/**
 * 用药时长说明备注(其他，请描述)
 */
export const genMedicationDurationExplainRemarkModel = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const _isShowMedicationDurationExplainRemark = computed(() => {
    return isShowMedicationDurationExplainRemark(modelRef);
  });

  return [_isShowMedicationDurationExplainRemark];
};

export const isShowOtherDesc = (modelRef: IFormMedicationDTO<any, any>) => {
  return modelRef.salesIncreaseReason === SalesIncreaseReasonType.OTHER;
};

/**
 * 用药出现原因(其他，请描述)
 */
export const genOtherDescModel = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const _isShowOtherDesc = computed(() => isShowOtherDesc(modelRef));

  return [_isShowOtherDesc];
};

export const isShowDelayEnterReasonOther = (modelRef: IFormMedicationDTO<any, any>) => {
  return modelRef.delayEnterReason === delayEnterReasonType.OTHER;
};

/**
 * 延迟录入原因(其他，请描述)
 */
export const getDelayEnterReasonOtherModel = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const _isShowDelayEnterReasonOther = computed(() => isShowDelayEnterReasonOther(modelRef));

  return [_isShowDelayEnterReasonOther];
};

/**
 * 展示延迟录入原因
 */
export const isShowDelayEnterReason = (modelRef: IFormMedicationDTO<any, any>) => {
  return modelRef.delayOrSale === 'DELAY';
};

/**
 * 展示用药出现原因
 */
export const isShowSalesIncreaseReason = (modelRef: IFormMedicationDTO<any, any>) => {
  return modelRef.delayOrSale === 'SALE';
};

export const isShowMedicationDurationExplain = (modelRef: IFormMedicationDTO<any, any>) => {
  const preCheck = MedicationDurationHelper.preCheck(modelRef);
  if (!preCheck) {
    return false;
  }

  const medicationDuration = +modelRef.medicationDuration;
  return medicationDuration > 84;
};

/**
 * 用药时长说明
 */
export const genMedicationDurationExplainModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
): [() => Promise<void>, ComputedRef<boolean>] => {
  const _isShowMedicationDurationExplain = computed(() => isShowMedicationDurationExplain(modelRef));

  const handleMedicationDurationExplainChange = async () => {
    effectHandler.medicationDurationExplainRemark();
  };

  return [handleMedicationDurationExplainChange, _isShowMedicationDurationExplain];
};

/**
 * 用药时长显示（天）
 */
export const genMedicationDurationModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  effectHandler: MedicationFormEffectHandler,
) => {
  const medicationDurationModel = computed(() => {
    return MedicationDurationHelper.preCheck(modelRef) ? MedicationDurationHelper.calc(modelRef) : '';
  });

  return [medicationDurationModel];
};

/**
 * 注射剂量
 */
export const genInjectionDoseModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
) => {
  const injectionDoseDoubt = computed<IFormDoubt>(() => {
    const [hasFeedback, message] = InjectionDoseHelper.hasDoubt(modelRef);

    if (!hasFeedback) {
      return EMPTY_DOUBT;
    }

    return {
      help: message,
      class: 'form-item-warning',
    };
  });

  const handleInjectionDoseChange = () => {
    effectHandler.medicationDuration();
    effectHandler.unitDose();
  };

  return [handleInjectionDoseChange, injectionDoseDoubt];
};

/**
 * 获取注射剂量单位列表
 */
const getInjectionUnits = (modelRef: IFormMedicationDTO<moment.Moment, string[]>): string[] => {
  if (!modelRef.specificationsName) {
    return [];
  }

  return modelRef.specificationsName.includes('54IU')
    ? [InjectionUnitType.MG_PER_WEEK, InjectionUnitType.IU_PER_WEEK]
    : [InjectionUnitType.IU_PER_DAY];
};

/**
 * 注射剂量单位
 */
export const genInjectionUnitModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
) => {
  const injectionUnitModel = computedStringModel(modelRef, 'injectionUnit');

  const injectionUnits = computed(() => getInjectionUnits(modelRef));

  const handleInjectionUnitChange = () => {
    effectHandler.medicationDuration();
    effectHandler.unitDose();
  };

  return [injectionUnitModel, injectionUnits, handleInjectionUnitChange];
};

const handleBatchListChange = async (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  formRef: Ref<IFormMethod>,
  deliveryPrices: Ref<string[]>,
  batchNumbers: Ref<string[]>,
  step: 'batchNumber' | 'deliveryPrice',
  loadingHandler?: LoadingHandler,
) => {
  const clearValidateFields: string[] = [];

  try {
    switch (step) {
      case 'batchNumber': {
        console.log('handleBatchListChange ---- batchNumber');
        loadingHandler && loadingHandler(true);
        batchNumbers.value = await fetchBatchCodes(modelRef);
        if (!batchNumbers.value.length) {
          console.log('handleBatchListChange ---- batchNumber --- none-value');
          return;
        }
        modelRef.batchNumber = batchNumbers.value[0];
        clearValidateFields.push('batchNumber');
      }
      // eslint-disable-next-line no-fallthrough
      case 'deliveryPrice': {
        console.log('handleBatchListChange ---- deliveryPrice');
        loadingHandler && loadingHandler(true);
        deliveryPrices.value = await fetchDeliveryPrices(modelRef);
        if (!deliveryPrices.value.length) {
          return;
        }
        effectHandler.deliveryPrice(deliveryPrices.value[0]);
        modelRef.batchBalanceInventory = await fetchInventoryNum(modelRef);
        clearValidateFields.push('deliveryPrice', 'actualDeliveryPrice');
      }
    }
  } finally {
    if (clearValidateFields.length) {
      formRef.value.validateFields(clearValidateFields);
    }
    loadingHandler && loadingHandler(false);
  }
};
 
/**
 * 生成规格model
 */
export const genSpecificationsModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  formRef: Ref<IFormMethod>,
  deliveryPrices: Ref<string[]>,
  batchNumbers: Ref<string[]>,
  loadingHandler?: LoadingHandler,
) => {
  const specificationsModel = computedLabeledValueModel(modelRef, {
    idField: 'specificationsId',
    nameField: 'specificationsName',
  });

  const specifications = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  (async () => {
    specifications.value = await dictionary.getCurDictionaryWithDefault({
      type: DictionaryTypeEnum.Spec,
    });
    // 页面进入就触发“规格不存在”的校验
    formRef.value?.validateFields(['specificationsId']);
  })();

  const handleSpecificationsChange = async () => {
    effectHandler.specificationsId();
    formRef.value.validateFields(['hit', 'stop', 'injectionUnit']);

    handleBatchListChange(
      modelRef,
      effectHandler,
      formRef,
      deliveryPrices,
      batchNumbers,
      'batchNumber',
      loadingHandler,
    );
  };

  return [specificationsModel, specifications, handleSpecificationsChange];
};

const fetchBuyMedicineMechanisms = async (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  loadingHandler?: LoadingHandler,
) => {
  let list: DictionaryVo<DictionaryTypeEnum>[] = [];

  if (modelRef.buyMedicineProvinceId && modelRef.buyMedicineMechanismType) {
    loadingHandler && loadingHandler(true);

    list = await dictionary.getCurDictionaryWithDefault({
      type: DictionaryTypeEnum.Hospital,
      provinceId: modelRef.buyMedicineProvinceId,
      hospitalType: modelRef.buyMedicineMechanismType,
    });

    loadingHandler && loadingHandler(false);
  }

  return list;
};

/**
 * 购药机构名称model
 */
export const genBuyMedicineMechanismModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  formRef: Ref<IFormMethod>,
  deliveryPrices: Ref<string[]>,
  batchNumbers: Ref<string[]>,
  hospitalLableInfo: Ref<any>,
  loadingHandler?: LoadingHandler,
): ModelGeneratorReturnType => {
  const buyMedicineMechanismModel = computedLabeledValueModel(modelRef, {
    idField: 'buyMedicineMechanismId',
    nameField: 'buyMedicineMechanismName',
  });

  const buyMedicineMechanisms = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  (async () => {
    buyMedicineMechanisms.value = await fetchBuyMedicineMechanisms(modelRef, loadingHandler);
  })();

  const handleBuyMedicineMechanismChange = async (val: LabeledValue) => {
    loadingHandler && loadingHandler(true);

    effectHandler.batchNumber();
    handleBatchListChange(
      modelRef,
      effectHandler,
      formRef,
      deliveryPrices,
      batchNumbers,
      'batchNumber',
      loadingHandler,
    );
    hospitalLableInfo.value = await getHospitalLabelInfo({
      hospitalId: val.key,
    });

    loadingHandler && loadingHandler(false);
  };

  return [buyMedicineMechanismModel, buyMedicineMechanisms, handleBuyMedicineMechanismChange];
};

export const isShowAutarky = (modelRef: IFormMedicationDTO<any, any>) => {
  return [HospitalType.RESIDENT_COOPERATIVE_CLINIC, HospitalType.NON_RESIDENT_COOPERATIVE_CLINIC].includes(
    modelRef.buyMedicineMechanismType as HospitalType,
  );
};

/**
 * 生成是否自营model
 */
export const genautarkyModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
): [WritableComputedRef<string>, Ref<string[]>, ComputedRef<boolean>] => {
  const autarkyModel = computedStringModel(modelRef, 'autarky');

  const autarkies = ref<string[]>(Autarkies);

  const _isShowAutarky = computed(() => {
    return isShowAutarky(modelRef);
  });

  return [autarkyModel, autarkies, _isShowAutarky];
};

/**
 * 生成购药机构类型model
 */
export const genBuyMedicineMechanismTypeModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  buyMedicineMechanisms: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  hospitalLableInfo: Ref<any>,
  loadingHandler?: LoadingHandler,
) => {
  const buyMedicineMechanismTypeModel = computedStringModel(modelRef, 'buyMedicineMechanismType');

  const buyMedicineMechanismTypes = ref<string[]>(HospitalTypes);

  const handleBuyMedicineMechanismTypeChange = async () => {
    effectHandler.buyMedicineMechanismId();
    effectHandler.autarky();
    buyMedicineMechanisms.value = await fetchBuyMedicineMechanisms(modelRef, loadingHandler);
    hospitalLableInfo.value = {};
  };

  return [buyMedicineMechanismTypeModel, buyMedicineMechanismTypes, handleBuyMedicineMechanismTypeChange];
};

/**
 * 生成购药省份model
 */
export const genBuyMedicineProvinceModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
  effectHandler: MedicationFormEffectHandler,
  buyMedicineMechanisms: Ref<DictionaryVo<DictionaryTypeEnum>[]>,
  hospitalLableInfo: Ref<any>,
  loadingHandler?: LoadingHandler,
) => {
  const buyMedicineProvinceModel = computedLabeledValueModel(modelRef, {
    idField: 'buyMedicineProvinceId',
    nameField: 'buyMedicineProvinceName',
  });

  const buyMedicineProvinces = ref<DictionaryVo<DictionaryTypeEnum>[]>([]);

  (async () => {
    buyMedicineProvinces.value = filterAllRegion(
      await dictionary.getCurDictionaryWithDefault({
        type: DictionaryTypeEnum.Province,
      }),
      DictionaryTypeEnum.Province,
    );
  })();

  const handleBuyMedicineProvinceChange = async () => {
    effectHandler.buyMedicineProvinceId();
    hospitalLableInfo.value = {};
    buyMedicineMechanisms.value = await fetchBuyMedicineMechanisms(modelRef, loadingHandler);
  };

  return [buyMedicineProvinceModel, buyMedicineProvinces, handleBuyMedicineProvinceChange];
};

export const isShowDisqualificationReasonType = (modelRef: IFormMedicationDTO<any, any>) => {
  return modelRef.statusIdentification === QualifiedStatusType.UNQUALIFIED;
};

/**
 * 生成不合格原因model
 */
export const genDisqualificationReasonModel = (
  modelRef: IFormMedicationDTO<moment.Moment, string[]>,
): [Ref<string[]>, ComputedRef<boolean>] => {
  const disqualificationReasonTypes = ref(DisqualificationReasonTypes);

  const _isShowDisqualificationReasonType = computed(() => {
    return isShowDisqualificationReasonType(modelRef);
  });

  return [disqualificationReasonTypes, _isShowDisqualificationReasonType];
};

export const isShowNextPurchaseOtherDesc = (modelRef: IFormMedicationDTO<any, any>) => {
  return (
    isShowDisqualificationReasonType(modelRef) && (modelRef.disqualificationReason as string[]).includes(OPTION_OTHER)
  );
};

export const isShowDrugCustomerRemark = (modelRef: IFormMedicationDTO<any, any>) => {
  return !!modelRef.drugCustomerRemark;
};

/**
 * 生成不合格原因备注(其他，请描述)model
 */
export const genNextPurchaseOtherDescModel = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const _isShowNextPurchaseOtherDesc = computed(() => {
    return isShowNextPurchaseOtherDesc(modelRef);
  });

  return [_isShowNextPurchaseOtherDesc];
};

/**
 * 生成注射频率校验
 */
export const genStopModel = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const stopDoubt = computed<IFormDoubt>(() => {
    const [hasFeedback, message] = FrequencyHelper.hasDoubt(modelRef);

    if (!hasFeedback) {
      return EMPTY_DOUBT;
    }

    return {
      help: message,
      class: 'form-item-warning',
    };
  });

  return [stopDoubt];
};

export const genActualDeliveryPrice = (modelRef: IFormMedicationDTO<moment.Moment, string[]>) => {
  const actualDeliveryPriceDoubt = computed<IFormDoubt>(() => {
    const [hasDoubt, doubtMessage] = ActualDeliveryPriceHelper.hasDoubt(modelRef);
    return hasDoubt
      ? {
          help: doubtMessage,
          class: 'form-item-warning',
        }
      : EMPTY_DOUBT;
  });
  return actualDeliveryPriceDoubt;
};

//资料不规范
export const genMaterialIrregularityOptions = () => {
  const causeOfIrregularityOptions = ref<any>([]);
  (async () => {
    const res = await getSysConfigInfo('material_irregularities');
    try {
      causeOfIrregularityOptions.value = JSON.parse(res);
    } catch (error) {
      console.log(error);
    }
  })();
  return causeOfIrregularityOptions;
};

// 购药不规范
export const genDrugIrregularityOptions = () => {
  const causeOfIrregularityOptions = ref<any>([]);
  (async () => {
    const res = await getSysConfigInfo('drug_irregularities');
    try {
      causeOfIrregularityOptions.value = JSON.parse(res);
    } catch (error) {
      console.log(error);
    }
  })();
  return causeOfIrregularityOptions;
};
