import { uniqueId } from 'lodash';

import { DepartmentLabelType, IFormTreatmentDTO, IndicationGroupType, IndicationType } from '@/type/formTreatmentDTO';

import { IFormPreviewGroup } from '../FormPreview/interface';
import { formatDateWithNone } from '../../utils';

const isShowAGHDRelated = (formValue: IFormTreatmentDTO): boolean => {
  return [IndicationGroupType.AGHD, IndicationGroupType.CGHD].includes(
    formValue.indicationGroupId as IndicationGroupType,
  );
};

const isShowDepartmentsOtherRemark = (formValue: IFormTreatmentDTO): boolean => {
  return isShowAGHDRelated(formValue) && formValue.departmentLabel === DepartmentLabelType.OTHERS;
};

const isShowIndicationRemark = (formValue: IFormTreatmentDTO): boolean => {
  return [IndicationType.ORHER_CHILDREN, IndicationType.OTHER_ADULT, IndicationType.OTHER_SURGICAL_GROUP].includes(
    formValue.indicationId as IndicationType,
  );
};

const isShowCustomerRemark = (formValue: IFormTreatmentDTO): boolean => {
  return !!formValue.customerRemark;
};

const previewConfig: IFormPreviewGroup<IFormTreatmentDTO>[] = [
  {
    id: uniqueId('TreatmentForm'),
    groupName: false,
    items: [
      { label: '首诊日期', fieldName: 'visitDate', format: (val) => formatDateWithNone(val) },
      { label: '患者群体', fieldName: 'indicationGroupName' },
      { label: '领域标签', fieldName: 'domainLabel', isShow: isShowAGHDRelated },
      { label: '科室标签', fieldName: 'departmentLabel', isShow: isShowAGHDRelated },
      { label: '其他，请描述', fieldName: 'departmentsOtherRemark', isShow: isShowDepartmentsOtherRemark },
      { label: '适应症', fieldName: 'indicationName' },
      { label: '适应症详细诊断描述', fieldName: 'indicationDesc' },
      { label: '其他，请描述', fieldName: 'indicationRemark', isShow: isShowIndicationRemark },
      { label: '首诊剂量', fieldName: 'treatmentDose' },
      { label: '首诊省份', fieldName: 'visitedProvinceName' },
      { label: '首诊城市', fieldName: 'visitedCityName' },
      { label: '首诊医院', fieldName: 'visitedHospitalName' },
      { label: '首诊KH', fieldName: 'visitedDoctorName' },
      { label: '接待人', fieldName: 'receptionist' },
      { label: '身高管理师', fieldName: 'heightManager' },
      { label: '身高P值', fieldName: 'heightPValue' },
      { label: '性发育进程', fieldName: 'sexualDevelopmentProcess' },
      { label: '首诊身高(CM)', fieldName: 'visitHeight' },
      { label: '首诊体重(KG)', fieldName: 'visitWeight' },
      { label: '客服备注', fieldName: 'customerRemark', isShow: isShowCustomerRemark },
      { label: '备注', fieldName: 'remark' },
      { label: '首诊不规范项', fieldName: 'treatmentIrregularitiesStr' },
    ],
  },
];

export default previewConfig;
