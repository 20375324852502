import { IBaseFormDTO } from './formContent';
import { ObjectMap, WithNull } from './helper';

/**
 * 患者群体枚举类型
 * 注: 值为对应的jinsaiId
 *
 * @enum
 */
export enum IndicationGroupType {
  /**
   * 儿童期生长激素缺乏症
   * 英文全称: Children Growth Hormone Deficiency
   * 英文缩写: CGHD
   */
  CGHD = 'c52c8512-2200-4287-a5b0-975649004c89',

  /**
   * 成人生长激素缺乏症
   * 英文全称: Adult Growth Hormone Deficiency
   * 英文缩写: AGHD
   */
  AGHD = 'b2dee768-6ba9-4548-9189-633f406e6fcc',

  // 妇科
  FKHD = '78B3BBB3-957E-4A62-B6D5-F848164C209B',

  /* 外科组 */
  SURGICAL_GROUP = '78B3BBB3-957E-4A62-B6D5-F848164C209B',
}

/**
 * 领域标签枚举类型
 *
 * @enum
 */
export enum DomainLabelType {
  /* 经典AGHD */
  CLASSIC_AGHD = '经典AGHD',

  /* 男科 */
  ANDROLOGY = '男科',

  /* 抗衰 */
  ANTIFADING = '抗衰',

  /* 其他 */
  // OTHER = '其他',
}

/**
 * 领域标签枚举翻译
 */
export const DomainLabelTransDict: ObjectMap<string> = {
  [DomainLabelType.CLASSIC_AGHD]: '经典AGHD',
  [DomainLabelType.ANDROLOGY]: '男科',
  [DomainLabelType.ANTIFADING]: '抗衰',
  // [DomainLabelType.OTHER]: '其他',
};

/**
 * 科室标签枚举类型
 *
 * @enum
 */
export enum DepartmentLabelType {
  /* 内分泌科 */
  ENDOCRINE = '内分泌科',

  /* 康复科 */
  REHABILITATION = '康复科',

  /* 神经外科 */
  NEUROSURGERY = '神经外科',

  /* 重症/NICU */
  SEVERE_NICU = '重症/NICU',

  /* 男科 */
  ANDROLOGY = '男科',

  /* 妇科 */
  GYNAECOLOGY = '妇科',

  /* 抗衰 */
  ANTIFADING = '抗衰',

  /* 感染科 */
  INFECTIOUS_DISEASE = '感染科',

  /* 老年科 */
  GERIATRICS = '老年科',

  /* 消化科 */
  GASTROENTEROLOGY = '消化科',

  /* 其他(备注) */
  OTHERS = '其他(备注)',

  /* 神经内科 */
  INTERNALNEUROSURGERY = '神经内科',

  /* 泌尿外科 */
  UROLOGY = '泌尿外科',

  /* 中医男科 */
  CHINESEMEDICINEANDROLOGY = '中医男科',

  /* 生殖男科 */
  REPRODUCTIVEANDROLOGY = '生殖男科',

  /* 睡眠科 */
  SLEEP = '睡眠科',

  /* 中医科 */
  CHINESEMEDICINE = '中医科',

  /* 整形外科 */
  PLASTICSURGERY = '整形外科',

  /* 全科 */
  GENERALPRACTICE = '全科',

  // CHILDNEPHROLOGYINNER = '儿童肾脏内科',
  CHILDNEPHROLOGY = '儿肾科',
  // CHILDNEPHROLOGYINNERABBR = '儿肾内科',
  // NEPHROLOGYINNER = '肾内科',
  // NEPHROLOGY = '肾科',
  RHEUMATISMIMMUNITY = '风湿免疫科',
  // RHEUMATISM = '风湿科',
  // IMMUNITY = '免疫科',
}

/**
 * 科室标签枚举翻译
 */
export const DepartmentLabelTransDict = {
  [DepartmentLabelType.ENDOCRINE]: '内分泌科',
  [DepartmentLabelType.REHABILITATION]: '康复科',
  [DepartmentLabelType.NEUROSURGERY]: '神经外科',
  [DepartmentLabelType.SEVERE_NICU]: '重症/NICU',
  [DepartmentLabelType.ANDROLOGY]: '男科',
  [DepartmentLabelType.GYNAECOLOGY]: '妇科',
  [DepartmentLabelType.ANTIFADING]: '抗衰',
  [DepartmentLabelType.INFECTIOUS_DISEASE]: '感染科',
  [DepartmentLabelType.GERIATRICS]: '老年科',
  [DepartmentLabelType.GASTROENTEROLOGY]: '消化科',
  [DepartmentLabelType.OTHERS]: '其他(备注)',
  [DepartmentLabelType.INTERNALNEUROSURGERY]: '神经内科',
  [DepartmentLabelType.UROLOGY]: '泌尿外科',
  [DepartmentLabelType.CHINESEMEDICINEANDROLOGY]: '中医男科',
  [DepartmentLabelType.REPRODUCTIVEANDROLOGY]: '生殖男科',
  [DepartmentLabelType.SLEEP]: '睡眠科',
  [DepartmentLabelType.CHINESEMEDICINE]: '中医科',
  [DepartmentLabelType.PLASTICSURGERY]: '整形外科',
  [DepartmentLabelType.GENERALPRACTICE]: '全科',
};

/**
 * 适应症枚举类型
 * 注: 值为对应的jinsaiId
 *
 * @enum
 */
export enum IndicationType {
  /* 其他(儿童) */
  ORHER_CHILDREN = '5C5EB4A4-8D8B-4B14-B773-3A7990B20640',

  /* 其他(成人) */
  OTHER_ADULT = '53F3CF86-93AA-4DB0-8EB3-56E7ED34FF40',

  /* 其他(外科组) */
  OTHER_SURGICAL_GROUP = '3C7942F9-BDB2-4291-AD64-EA1E2701F42D',
}

/**
 * 身高P值枚举
 *
 * @enum
 */
export enum HeightPType {
  /* 小于等于P3 */
  LESS_EQUAL_P3 = '小于等于P3',
  /* P3-P10 */
  FROM_P3_TO_P10 = 'P3-P10',
  /* P10-P25 */
  FROM_P10_TO_P25 = 'P10-P25',
  /* P25-P50 */
  FROM_P25_TO_P50 = 'P25-P50',
  /* 大于等于P50 */
  MORE_EQUAL_P50 = '大于等于P50',
}

/**
 * 身高P值枚举翻译
 */
export const HeightPTransDict: ObjectMap<string> = {
  [HeightPType.LESS_EQUAL_P3]: '小于等于P3',
  [HeightPType.FROM_P3_TO_P10]: 'P3-P10',
  [HeightPType.FROM_P10_TO_P25]: 'P10-P25',
  [HeightPType.FROM_P25_TO_P50]: 'P25-P50',
  [HeightPType.MORE_EQUAL_P50]: '大于等于P50',
};

/**
 * 首诊信息表单结构
 */
export interface IFormTreatmentDTO<TDateType = string> extends IBaseFormDTO {
  /* 模块名称 */
  moduleName: '首诊信息';

  /* 首诊日期 */
  visitDate: WithNull<TDateType>;

  /* 患者群体ID */
  indicationGroupId: string;

  /* 患者群体 */
  indicationGroupName: string;

  /* 适应症ID */
  indicationId: string;

  /* 适应症 */
  indicationName: string;

  /** 适应症详细诊断描述 */
  indicationDesc: string;

  /* 适应症备注,-适应症：选择其他（儿童）、其他（成人）后，需要手动录入备注内容 */
  indicationRemark: string;

  /* 领域标签 */
  domainLabel: string;

  /* 科室标签 */
  departmentLabel: string;

  /* 科室标签的其他请描述 */
  departmentsOtherRemark: string;

  /* 首诊剂量 */
  treatmentDose: string;

  /* 首诊省份ID */
  visitedProvinceId: string;

  /* 首诊省份 */
  visitedProvinceName: string;

  /* 首诊城市ID */
  visitedCityId: string;

  /* 首诊城市 */
  visitedCityName: string;

  /* 首诊医院ID */
  visitedHospitalId: string;

  /* 首诊医院 */
  visitedHospitalName: string;

  /* 首诊医生ID */
  visitedDoctorId: string;

  /* 首诊医生 */
  visitedDoctorName: string;

  /* 接待人ID */
  receptionistId: string;

  /* 接待人 */
  receptionist: string;

  /* 身高管理师ID */
  heightManagerId: string;

  /* 身高管理师 */
  heightManager: string;

  /* 身高P值 */
  heightPValue: string;

  /* 性发育进程 */
  sexualDevelopmentProcess: string;

  /* 首诊身高(CM) */
  visitHeight: string;

  /* 首诊体重(KG) */
  visitWeight: string;

  /* 客服备注 */
  customerRemark: string;

  /* 备注 */
  remark: string;
  treatmentIrregularities: any;
}
